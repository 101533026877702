import request from '@/utils/request'

export function addServer(data) {
  return request({
    url: 'api/addServer',
    method: 'POST',
    data
  })
}

export function updateServer(data) {
  return request({
    url: 'api/updateServer',
    method: 'put',
    data
  })
}

export function loadNewServer(data) {
  return request({
    url: 'api/loadNewServer',
    method: 'GET',
    params:data
  })
}

